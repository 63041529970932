import React from "react";
import Slider from "react-slick";
import loadable from "@loadable/component";
const ImageLoader = loadable(() => import("./../common/ImageLoader"));

import { EMOJI } from "../../../shared/enums";

import halloweenHero from "assets/images/heroHalloween/halloween-hero.png";
import halloweenHeroWebp from "assets/images/heroHalloween/halloween-hero.png?as=webp";
import halloweenHero01 from "assets/images/heroHalloween/halloween-hw.png";
import halloweenHero01Webp from "assets/images/heroHalloween/halloween-hw.png?as=webp";
import halloweenHero02 from "assets/images/heroHalloween/halloween-rp.png";
import halloweenHero02Webp from "assets/images/heroHalloween/halloween-rp.png?as=webp";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const itemList = [
  /*
  {
    color: "#F27E0F",
    title: (
      <>
        {" "}
        Save up to
        <strong>31%</strong>
      </>
    ),
    image: {
      url: halloweenHero,
      urlWebp: halloweenHeroWebp,
    },
    content: {
      title: (
        <>
          Your <strong>air quality</strong> shouldn’t scare you.{" "}
          {EMOJI.WEARY_CAT}
        </>
      ),
      description: {
        desktop: (
          <>
            {" "}
            Keep it clean with Mila, the award-winning air purifier with wicked
            smarts.
          </>
        ),
        mobile: (
          <>
            {" "}
            Keep it clean with Mila, the award-winning <br /> air purifier with
            wicked smarts.
          </>
        ),
      },
    },
  },
  */
  {
    color: "#F27E0F",
    title: (
      <>
        {" "}
        Save up to
        <strong>31%</strong>
      </>
    ),
    image: {
      url: halloweenHero01,
      urlWebp: halloweenHero01Webp,
    },
    content: {
      title: <>Protecting you from the  “new home” smell.</>,
      description: {
        desktop: (
          <>
            {" "}
            Meet the filter that tackles the particulates and VOCs from all your
            renovation projects.
          </>
        ),
        mobile: (
          <>
            {" "}
            Meet the filter that tackles the particulates <br /> and VOCs from
            all your projects 🛠
          </>
        ),
      },
    },
  },
  {
    color: "#8852F6",
    title: (
      <>
        Save up to
        <strong>29%</strong>
      </>
    ),
    image: {
      url: halloweenHero02,
      urlWebp: halloweenHero02Webp,
    },
    content: {
      title: <>Cleaner air for you and your littles.</>,
      description: {
        desktop: (
          <>
            Meet the filter that works overtime for the whole fam to filter out
            all the odors, particulates and VOCs.💗
          </>
        ),
        mobile: (
          <>
            Meet the filter that works overtime <br /> for the whole fam.💗
          </>
        ),
      },
    },
  },
];
const HeroHalloween = () => {
  const awardSettings = {
    autoplay: true,
    speed: 600,
    cssEase: "cubic-bezier(0.65,0.05,0.36,1)",
    slidesToShow: 4,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    pauseOnFocus: false,
    arrows: false,

    responsive: [
      {
        mobileFirst: true,
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          mobileFirst: true,
        },
      },
    ],
  };

  const settings = {
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToScroll: 1,
    slidesToShow: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
  };

  return (
    <section className="hero-halloween">
      <div className="hero-halloween__card">
        <div className="awards awards--four">
          <div className="container">
            <Slider {...awardSettings} className="awards__slider">
              <blockquote className="awards__item">
                <div className="awards__itemInner">
                  <h2>
                    ‘Best
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>Popular Science</cite>
                </div>
              </blockquote>
              <blockquote className="awards__item">
                <div className="awards__itemInner">
                  <h2>
                    ‘A Truly Smart
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>NYTimes/Wirecutter</cite>
                </div>
              </blockquote>
              <blockquote className="awards__item">
                <div className="awards__itemInner">
                  <h2>
                    ‘Best Smart
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>Homes & Gardens</cite>
                </div>
              </blockquote>
              <blockquote className="awards__item">
                <div className="awards__itemInner">
                  <h2>
                    ‘Best Smart
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>Gear Patrol</cite>
                </div>
              </blockquote>
            </Slider>
          </div>
        </div>
        <Slider {...settings} className="hero-halloween__slider">
          {itemList.map((item, index) => (
            <div
              className={`hero-halloween__item hero-halloween__item--${index}`}
              key={index}
            >
              <h1
                className="hero-halloween__title"
                style={{ "--color": item.color }}
              >
                {item.title}
              </h1>
              <ImageLoader
                className="hero-halloween__media img-relative"
                placeholderAspectRatio={1631 / 839}
                image={item.image.url}
                webp={item.image.urlWebp}
                alt={`Mila Halloween ${index + 1}`}
              />
              <div className="hero-halloween__content">
                <h2 className="hero-halloween__subtitle">
                  {item.content.title}
                </h2>
                <p className="hero-halloween__content-text hero-halloween__content-text--desktop">
                  {item.content.description.desktop}
                </p>
                <p className="hero-halloween__content-text hero-halloween__content-text--mobile">
                  {item.content.description.mobile}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="price-table">
        <div className="container">
          <div className="price-table__footer">
            <h3 className="price-table__title">The Mila Promise</h3>
            <ul className="price-table__list">
              <li>
                <svg className="icon icon-money-back-guarantee">
                  <use xlinkHref="#icon-money-back-guarantee" />
                </svg>
                30 day Mila-backed guarantee
              </li>
              <li>
                <svg className="icon icon-shipping">
                  <use xlinkHref="#icon-shipping" />
                </svg>
                Fast free shipping with same/next business day shipping
              </li>
              <li>
                <svg className="icon icon-warranty">
                  <use xlinkHref="#icon-warranty" />
                </svg>
                One year warranty
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroHalloween;
